import React from 'react';
import Loading from './../components/Loading';

function NotFound() {

  return (
  <>
    <Loading></Loading>
    <main class="main">
      <p>Not Found</p>  
    </main>
  </>
  );
}

export default NotFound;