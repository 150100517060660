import React from 'react';
import Loading from './../components/Loading';

function About() {

  return (
  <>
    <main class="main">
      <Loading></Loading>
      <p>About us</p>
    </main>  
  </>
            

  );
}

export default About;