import React from 'react';
import Loading from './../components/Loading';

function Policy() {

  return (
  <>
    <Loading></Loading>
    <main class="main">
      <p>Policy</p>
    </main>
  </>
  );
}

export default Policy;